import { localStorageKeys } from '@constants';
import { useLocalStorage } from 'admin-portal-shared-services';
import { useState } from 'react';
import { UsePagination } from './usePagination.types';

export const usePagination: UsePagination = (options) => {
  const { initialRowsPerPage = 10, pageName } = options;
  const { localStoragePageSize } = localStorageKeys(pageName);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useLocalStorage(localStoragePageSize, initialRowsPerPage);

  const onChangePagination = (page: number, pageSize: number) => {
    setPage(page - 1);
    setPageSize(pageSize);
  };

  return {
    page,
    setPage,
    pageSize,
    onChangePagination,
  };
};
