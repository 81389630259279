import { Chip } from '@hexa-ui/components';
import { useFilterContext } from '../context/FilterContext';
import * as Styles from './FilterSelected.styles';

export const FilterSelected = () => {
  const { fields, filters, handleDelete } = useFilterContext();

  return (
    <Styles.Container data-testid="filter-selected" direction="row">
      {Object.keys(filters).map((field) => {
        if (!filters[field].length) {
          return;
        }

        return (
          <Chip.Root key={field}>
            <Chip.Label weight="medium" size="small">
              {`${fields[field].label}: `}
              {filters[field].map((filter: string) => filter).join(', ')}
            </Chip.Label>
            <Chip.DeleteIcon onDelete={() => handleDelete(field)} />
          </Chip.Root>
        );
      })}
    </Styles.Container>
  );
};
