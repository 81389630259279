import { LoadingBuzz } from '@hexa-ui/components';
import {
  Card as CardStyled,
  LeverContainer,
  LeverLabel,
  ProgressMeterContainer,
  LeverTitle,
  TooltipContainer,
  RewardSummary,
  PotentialReward,
  RewardValue,
  Loading,
} from './InvoiceLeverLoading.style';
import { useIntl } from 'react-intl';

function InvoiceLeverLoading() {
  const { formatMessage } = useIntl();
  return (
    <CardStyled border="medium" elevated="medium">
      <LeverContainer data-testid={'invoice-lever-loading'}>
        <div>
          <LoadingBuzz size="xlarge" />
        </div>
        <Loading>
          {formatMessage({ id: 'pages.PINVisibility.kpiPage.pinLevers.cards.loading' })}
        </Loading>
        <Loading>
          {formatMessage({ id: 'pages.PINVisibility.kpiPage.pinLevers.cards.loadingAux' })}
        </Loading>
      </LeverContainer>
    </CardStyled>
  );
}

export default InvoiceLeverLoading;
