import { FileValidated } from '@hexa-ui/components';

export const getAllExtensions = (files: FileValidated[]) => {
  if (!files || !Array.isArray(files)) {
    return '';
  }
  const extensions = files.map((file) => {
    const filename = file.file.name;
    const parts = filename.split('.');
    return `.${parts.pop()}`;
  });
  return extensions.join(', ');
};

export const getAllSize = (files: FileValidated[]) => {
  if (!files || !Array.isArray(files)) {
    return '';
  }
  const sizes = files.map((file) => {
    const bytes = file.file.size;
    const sizesConverted = bytes / 1024;
    if (sizesConverted > 1024) {
      const mb = sizesConverted / 1024;
      return `${mb.toFixed(2).toString()} MB`;
    } else {
      return `${sizesConverted.toFixed(2).toString()} KB`;
    }
  });
  return sizes.join(', ');
};

export const getAllNames = (files: FileValidated[]) => {
  if (!files || !Array.isArray(files)) {
    return '';
  }
  const names = files.map((file) => {
    return file.file.name;
  });
  return names.join(', ');
};

export const convertSize = (bytes: number) => {
  const sizesConverted = bytes / 1024;
  if (sizesConverted > 1024) {
    const mb = sizesConverted / 1024;
    return `${mb.toFixed(2).toString()} MB`;
  } else {
    return `${sizesConverted.toFixed(2).toString()} KB`;
  }
};
