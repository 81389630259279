import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { get as getApi } from '../../api';
import { CONTRACT_BASE_URL } from '../contract';

export const useHistoryService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;

  const useGet = (clientId: string, vendorId?: string) => {
    const {
      data = {
        content: {
          KPIs: [],
        },
      },
      refetch,
    } = useQuery(
      ['contract', clientId, vendorId, selectedCountry],
      async () => {
        const url = vendorId
          ? `${CONTRACT_BASE_URL}/history/byAccountIDAndVendorID?accountID=${clientId}&vendorID=${vendorId}`
          : `${CONTRACT_BASE_URL}/history?chainId=${clientId}`;

        return getApi({
          url,
          config: {
            headers: {
              requestTraceId: 'contract-history',
              country: selectedCountry,
            },
          },
        });
      },
      {
        enabled: true,
        staleTime: 0,
        keepPreviousData: false,
      }
    );
    return { data: data.content, refetch };
  };
  const useGetHistoryDetails = (contractId: string) => {
    const {
      data = {
        content: {
          KPIs: [],
        },
      },
      status,
      refetch,
      isError,
    } = useQuery(
      ['contract-details', contractId],
      () => {
        const url = `${CONTRACT_BASE_URL}/history/details?contractId=${contractId}`;
        return getApi({
          url,
          config: {
            headers: {
              requestTraceId: 'contract-history',
              country: selectedCountry,
            },
          },
        });
      },
      {
        enabled: true,
        staleTime: 0,
        keepPreviousData: false,
      }
    );
    return { data: data.content, status, refetch, isError };
  };

  return {
    useGet,
    useGetHistoryDetails,
  };
};
