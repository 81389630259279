import { useSidebarService } from '@api';
import { SidebarItem, useSidebar } from 'admin-portal-shared-services';
import { createElement, useEffect, useState } from 'react';

export type THexaIcons = typeof import('@hexa-ui/icons');

export const renderIcon = (icons: THexaIcons, icon: string) => {
  const HexaIcon = icons[icon] ?? icons.Menu;

  return () => createElement(HexaIcon);
};

export const useDynamicSidebar = () => {
  const { data, isSuccess } = useSidebarService();
  const [items, setItems] = useState<SidebarItem[]>([]);

  useEffect(() => {
    if (isSuccess) {
      /* istanbul ignore next */
      import('@hexa-ui/icons')
        .then((icons) => {
          setItems(
            data.map((item) => ({
              ...item,
              icon: renderIcon(icons, item.icon),
            }))
          );
        })
        .catch((error) => console.error(error));
    }
  }, [isSuccess, data]);

  useSidebar({
    items,
    utils: [],
  });
};
