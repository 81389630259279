import { Heading, ProgressMeter } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ProgressBar = styled(ProgressMeter, {
  '& > progress': {
    '&::-webkit-progress-bar': {
      height: 8,
    },
    margin: 0,
  },
});

export const LeverBarContainer = styled('div', {
  width: '100%',
});

export const CurrentAchievementValue = styled('h1', {
  fontSize: '$6',
  fontFamily: '$heading',
  margin: '0',

  '&.sucess': { color: '$semanticSuccessText' },
});

export const Title = styled(Heading, {
  marginBottom: '15px',
});

export const TotalTarget = styled('p', {
  fontSize: '$1',
  fontFamily: '$paragraph',
  margin: 0,
  marginTop: '$025',
});

export const PotentialPrize = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
});
