import { Heading, Card as HexaCard, Divider as HexaDivider, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '210px',
  width: 'calc(46.7% - 32px)',
  padding: '$4',
  margin: '4px',
  zIndex: '1',
});

export const LeverContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  spaceBetween: 'center',
  justifyContent: 'space-between',
  padding: '$6',
  marginBottom: '$1',
});

export const LeverTitle = styled(Paragraph, {
  fontSize: '1rem',
  marginBottom: '$1',
  maxWidth: '90%',
});

export const TooltipContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '$1',
  maxWidth: '10%',
  paddingRight: 'var(--space-24)',
});

export const LeverLabel = styled(Paragraph, {
  fontSize: '32px',
  marginBottom: '8px',
});

export const ProgressMeterContainer = styled('div', {});

export const Loading = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
});

export const RewardSummary = styled(Paragraph, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  maxWidth: '100%',
});

export const PotentialReward = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'collumn',
  width: '100%',
});

export const RewardValue = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'collumn',
  width: '100%',
});
