import { styled } from '@hexa-ui/theme';

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
});

export const Container = styled('div', {
  flexDirection: 'row',

  '& .select-date + .select-date': {
    marginLeft: '$4',
  },
});
