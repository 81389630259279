export interface DownloadFileProps {
  url: string;
  fileName?: string;
  fileExt?: string;
}

export interface DownloadFileSummaryProps {
  downloadedSuccessfull: boolean;
  errorDownloadFile: string | null;
}

export function downloadFile({
  url,
  fileName,
  fileExt,
}: DownloadFileProps): DownloadFileSummaryProps {
  try {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${fileName}.${fileExt}`;
    downloadLink.click();
    return { downloadedSuccessfull: true, errorDownloadFile: null };
  } catch (error) {
    return { downloadedSuccessfull: false, errorDownloadFile: error.message };
  }
}
