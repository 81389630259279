import { SkeletonLoader } from '@hexa-ui/components';
import { ItemDefaultFalse } from '../index.styles';
import './index.styles.ts';

const TabMenuItemLoading = () => {
  return (
    <ItemDefaultFalse>
      <span className={`tab-menu_text_false`}>
        <SkeletonLoader width="185px" height="48px" data-testid="skeleton-loader" />
      </span>
    </ItemDefaultFalse>
  );
};
export default TabMenuItemLoading;
