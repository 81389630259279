import { styled } from '@hexa-ui/theme';
import { Stack } from '@mui/material';

export const Container = styled(Stack, {
  display: 'flex',
  margin: '0.9rem 0.5rem 0.9rem 2.5rem',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  flex: 1,
  gap: '$2',
});
