export const segmentScreenName = {
  homePage: 'Home Page',
  commercialAgreements: 'Commercial Agreements',
  contractManagment: 'Contract Management',
  jbpVisibilityPageViewed: 'JBP Tracking',
  contractGeneralInformation: 'Contract General Information',
  kpisPage: 'KPIs Page',
  contractDetailsPageViewed: 'Contract Details Page Viewed',
  contractDriversStepViewed: 'Contract KPIs',
  contractUpdate: 'Contracts update',
  constractCreation: 'Contract creation',
};

export const referrers = {
  linkAdmin: 'Link Admin',
  commercialAgreements: 'Commercial Agreements',
  contractManagment: 'Contract Management',
  jbpVisibility: 'JBP Visibility initial page',
};

export const buttonLabel = {
  saveProgress: 'Save Progress',
  close: 'Close',
  update: 'Update',
};

export const valueStreamNames = {
  linkJbp: 'LINK_JBP',
};
