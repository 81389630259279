import { ItemDefaultFalse, ItemDefaultTrue, TextFalse, TextTrue } from './index.styles';
import TabMenuItemLoading from './TabMenuItemLoading';

export const TabMenuItem = ({ content, onClick, active, isLoading }) => {
  if (isLoading) {
    return <TabMenuItemLoading />;
  }

  const ItemComponent = active === true ? ItemDefaultTrue : ItemDefaultFalse;
  const TextComponent = active === true ? TextTrue : TextFalse;

  return (
    <ItemComponent onClick={onClick} data-testid={`tab-menu-item-${content}`}>
      <TextComponent>{content}</TextComponent>
    </ItemComponent>
  );
};
