import { Paragraph } from '@hexa-ui/components';
import { IHeaderSubtitle } from './HeaderSubtitle.props';
import { TitleContainer } from './HeaderSubtitle.style';

export const HeaderSubtitle = ({ subTitle }: IHeaderSubtitle): JSX.Element => {
  return (
    <TitleContainer>
      <Paragraph size="basis" alignment="left">
        {subTitle}
      </Paragraph>
    </TitleContainer>
  );
};
