import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  width: '100%',
  minHeight: '514px',
  backgroundColor: '$neutral0',
  borderRadius: '$6',

  display: 'flex',
  flexDirection: 'column',
  padding: '$8',
  boxShadow: '0 0 16px 0 rgba(0,0,0,0.16)',
});
