import { FilterMenu } from '../FilterMenu/FilterMenu';
import { useFilterContext } from '../context/FilterContext';
import * as Styles from './FilterDrawer.styles';

export const FilterDrawer = () => {
  const { tempFilters, drawerOpen, handleClear, labels } = useFilterContext();

  const disabled = !Object.keys(tempFilters).find((filter) => tempFilters[filter][0] !== '');

  return (
    <Styles.Drawer
      data-testid="filter-drawer"
      variant="persistent"
      anchor="left"
      open={drawerOpen}
      drawerOpen={drawerOpen}
      transitionDuration={200}
    >
      <Styles.DrawerHeader>
        <Styles.Heading size="H4">{labels?.filters}</Styles.Heading>
        <Styles.Button size="small" variant="secondary" onClick={handleClear} disabled={disabled}>
          {labels?.clearAll}
        </Styles.Button>
      </Styles.DrawerHeader>
      <FilterMenu />
    </Styles.Drawer>
  );
};
