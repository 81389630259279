import { Select } from '@hexa-ui/components';
import { useFilterContext } from '../context/FilterContext';
import * as Styles from './FilterMenu.styles';

export const FilterMenu = () => {
  const { fields, tempFilters, handleChange, handleApply, labels } = useFilterContext();

  return (
    <Styles.Container data-testid="filter-menu" spacing={3}>
      {Object.keys(fields).map((fieldKey) => {
        const field = fields[fieldKey];
        const [value] = tempFilters[fieldKey] || [''];

        switch (field.type) {
          case 'select':
            return (
              <Select.Root
                key={fieldKey}
                size="medium"
                label={field.label}
                value={value}
                onChange={(value) => handleChange(fieldKey, value)}
                placeholder={field.placeholder}
              >
                {field.options?.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select.Root>
            );
          default:
            return (
              <Styles.Input
                key={fieldKey}
                width="100%"
                label={field.label}
                placeholder={field.placeholder}
                onChange={({ currentTarget }) => handleChange(fieldKey, currentTarget.value)}
                value={value}
              />
            );
        }
      })}
      <Styles.ApplyButton variant="secondary" onClick={handleApply}>
        {labels?.apply}
      </Styles.ApplyButton>
    </Styles.Container>
  );
};
