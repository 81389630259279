import { styled } from '@hexa-ui/theme';

export const ItemDefaultTrue = styled('div', {
  display: 'flex',
  height: '52px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '8px',
});
export const ItemDefaultFalse = styled('div', {
  display: 'flex',
  height: '52px',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '8px',
});
