import { Card, Paragraph } from '@hexa-ui/components';
import { Info, Store2 } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const CardContainer = styled('div', {
  marginTop: '$10',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '$4',
});

export const CardStyled = styled(Card, {
  width: '400px',
  height: '130px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '$6',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const CardContent = styled('div', {
  display: 'flex',
  margin: '$1',
});

export const CardTexts = styled('div', {
  marginLeft: '$2',
});

export const CardTitle = styled(Paragraph, {
  marginBottom: '$1',
  fontSize: '$4',
});

export const CardParagraph = styled(Paragraph, {
  lineHeight: '$5',
  color: '$neutral50 !important',
});

export const IconInfo = styled(Info, {
  color: '$interfaceDatavis1',
  marginRight: '$1',
});

export const IconStore = styled(Store2, {
  color: '$interfaceDatavis9',
  marginRight: '$1',
});
