import { enUS } from './en-US';

export const esMX: typeof enUS = {
  breadcrumb: {
    commercialAgreements: {
      title: 'Commercial Agreements',
    },
    JBPManagement: {
      title: 'JBP Management',
    },
    JBPVisibility: {
      title: 'JBP Tracking',
      kpiPage: {
        title: 'Commercial agreements details',
      },
    },
    JBPContractManagment: {
      title: 'Contract management',
      createContract: {
        title: 'Create contract',
      },
      createContractUpload: {
        title: 'Contract creation',
      },
    },
    JPBUpdateKPIs: {
      title: 'Actualización de metas',
    },
    PINVisibility: {
      title: 'Commercial agreements visibility',
      subTitle: 'Track the KPIs progress by chains and stores.',
      kpiPage: {
        title: 'Commercial agreements details',
      },
    },
    CatalogPage: {
      title: 'Catalog Page',
    },
    newInsights: {
      title: 'Commercial agreements details',
    },
  },
  leverBar: {
    targetAchieved: 'Target achieved',
    missingTargetFirst: ' ',
    missingTargetSecond: 'is missing for the target of ',
    potentialPrize: 'Potential payout',
  },
  leverDonut: {
    storesOnTarget: 'Stores on target',
    storesOutTarget: 'Stores out target',
    noData: 'No data',
    targetPerStore: 'Target per store',
    totalStoresTarget: 'Total stores target',
    potentialReward: 'Potential payout',
  },
  errorMessage: {
    400: 'Something went wrong. Please, try again later. If the problem persists, contact the support.',
    403: 'The information you are trying to access is not available.',
    500: 'Something went wrong. Please, try again later. If the problem persists, contact the support.',
    404: {
      buttonText: 'Go back',
      headerText: 'Mmm… Looks like this page is gone',
      subHeaderText: 'It looks like this page wasn’t found or doesn’t exist.',
    },
    503: {
      buttonText: 'Try again',
      headerText: 'Hm... Service unavailable!',
      infoText: 'If the issue persists, you can report the problem.',
      subheaderText:
        'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
    },
    leversPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'No agreements found',
        subtitle:
          "This chain doesn't have any registered agreements. Create a new deal or contract.",
        buttonText: 'Create new contract',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: 'Ooops! Something went wrong',
    },
    pinsPage: {
      403: {
        title: 'Hmm... Access denied!',
        subtitle: "It looks like this page wasn't found or doesn't exist.",
        buttonText: 'Home',
      },
      404: {
        title: 'No agreements found',
        subtitle:
          "This chain doesn't have any registered agreements. Create a new deal or contract.",
        buttonText: 'Create new contract',
      },
      503: {
        title: 'Hm... Service unavailable!',
        subtitle:
          'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
        buttonText: 'Try again',
        infoText: 'If the issue persists, you can report the problem.',
      },
      otherStatus: 'Ooops! Something went wrong',
    },
    newInsightsPage: {
      history: {
        404: {
          subtitle: 'No past contracts to show. Access your current contract.',
        },
      },
      currentContract: {
        404: {
          title: 'No agreements found',
          subtitle: "This chain doesn't have any registered agreements.",
          subtitleSecondary: 'Create a new deal or contract.',
          subtitleSelectedPeriod:
            "This chain doesn't have any registered agreements for the selected period.",
          firstAction: 'Create new deal',
          secondAction: 'Create new contract',
        },
      },
    },
    accessDenied: {
      title: 'Hmm... Access denied!',
      subtitle: "It looks like you don't have permission to access this page.",
      buttonText: 'Home',
    },
  },
  table: {
    rowsPerPage: 'Items per page',
    paginationCounter: 'of',
    emptyMessage: "There's no information to show.",
    loadingMessage: 'Loading data',
    selectable: {
      displayTextOneRowSelected: 'selected',
      displayTextMoreThanOneRowSelected: 'selected',
      displayTextDeselectAll: 'Deselect all',
    },
  },
  kpiComponent: {
    achievement: 'Achievement: ',
    currentProgress: 'Current progress: ',
    targetAchieved: 'Target achieved',
    overTarget: 'Over target',
    targetKpi: 'Target: ',
    remainingTarget: 'Remaining target:',
    amountOverTarget: 'Amount over target:',
    percentageAchieved: 'Percentage achieved: ',
    store: 'store',
    stores: 'stores',
  },
  rewardCard: {
    title: 'Payout earned',
    label: 'Payouts summary',
    targetProgress: {
      emptyState: 'Targets must be accepted to check the payouts.',
      supportInfo: {
        increase: 'earned last month',
        decrease: 'earned last month',
      },
      allCompleted: 'Pago máximo basado en los objetivos logrados para este KPI.',
    },
    totalRevenue: {
      toEarn: 'to earn',
      moreToEarn: 'more to earn',
    },
  },
  components: {
    growthKpiCard: {
      acceptButton: 'Accept',
      targetReachedMessage: 'Target reached!',
      invoicedOrder: 'Invoiced order',
      orderInProgress: 'Order in progress',
      targetLabel: 'Meta',
      currentTarget: 'Meta actual',
      remainingLabel: 'Remaining',
      rewardLabel: 'Recompensa',
      currentRebate: 'Recompensa actual',
      comparisonLabel: 'Up to',
      lastUpdateLabel: 'Last update',
      date: '%month %day, %year',
      trayButton: 'Mostrar detalles del objetivo',
      finalRewardDescription: 'Maximum guaranteed payout',
      orderInProgressAlertMessage:
        'Returned or undelivered products will not be included in the KPI progress.',
      measureTypes: {
        singular: {
          case: 'caja',
        },
        plural: {
          case: 'cajas',
        },
      },
      rewardTooltip: 'Payout based on the achievement for this KPI.',
    },
    numericKpiCard: {
      progress: '%progress of %target',
      targetReachedMessage: 'Target reached!',
      targetLabel: 'Target',
      remainingLabel: 'Remaining',
      rewardLabel: 'Potential payout',
      finalRewardLabel: 'Final payout',
      rewardTooltip: 'Payout based on the achievement for this KPI.',
      rewardTooltipIRS: 'Current payout based on the achieved targets for this KPI.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI.',
      lastUpdateLabel: 'Last update',
      measureTypes: {
        singular: {
          store: 'store',
        },
        plural: {
          store: 'stores',
        },
      },
    },
    percentageKpiCard: {
      progress: '%progress of %target',
      targetReachedMessage: 'Target reached!',
      targetLabel: 'Target',
      remainingLabel: 'Remaining',
      rewardLabel: 'Potential payout',
      finalRewardLabel: 'Final payout',
      maximumAchieved: 'Alcanzado el máximo',
      comparisonLabel: 'Up to',
      rewardTooltip: 'Payout based on the achievement for this KPI.',
      rewardTooltipIRS: 'Current payout based on the achieved targets for this KPI.',
      currentRewardTooltip: 'Current payout based on the achieved targets for this ICR.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI.',
      lastUpdateLabel: 'Last update',
      bulkText: 'Bulky packaging helps this target.',
      convenienceText: 'Disposable packaging helps this target.',
    },
    irsKpiCard: {
      targetReachedMessage: 'Exit reached!',
      errorMessage: 'Something went wrong.',
      notStartedMessage: 'An order of %value cases will increase this KPI by 10%.',
      inProgressMessage: '%value from last week',
      targetAchievedMessage: 'Your client must continue buying products to increase the payout!',
      exitAchievedMessage: 'Exit achieved! Maximum guaranteed payout.',
      invoicedOrder: 'Invoiced order',
      targetLabel: 'Target',
      exitLabel: 'Exit',
      rewardLabel: 'Potential payout',
      comparisonRewardLabel: 'Up to',
      finalRewardLabel: 'Final payout',
      finalRewardDescription: 'Maximum guaranteed payout',
      rewardTooltip: 'Payout based on the achieved targets for this KPI. Incl. VAT.',
      exitTooltip: 'Maximum cases amount to receive a payout.',
      targetTooltip: 'Minimum cases amount to start receiving a payout.',
      finalRewardTooltip: 'Maximum payout based on the achieved targets for this KPI. Incl. VAT.',
      lastUpdateLabel: 'Last update',
      lastUpdateDate: '%month %day, %year',
      measureTypes: {
        singular: {
          case: 'case',
        },
        plural: {
          case: 'cases',
        },
      },
    },
    generalKpiCardInfo: {
      lastUpdateDate: '%month %day, %year',
      statusLabel: {
        notStarted: 'Not started',
        waitingAcceptance: 'Waiting acceptance',
        accepted: 'Accepted',
        inProgress: 'In progress',
        achieved: 'Achieved',
        notAchieved: 'Not achieved',
        expired: 'Expired',
        almostAchieved: 'Partially achieved',
        overAchieved: 'Exceeded ',
        exceeded: 'Exceeded ',
      },
    },
    historyTable: {
      contract: 'Contract',
      contractId: 'Contract ID',
      targetsAchieved: 'Targets achieved',
      targetsAchievedMessage: '%s1 of %s2 KPIs',
      performanceMessage: '% from last contract',
      rewardEarned: 'Payout earned',
      rewardEarnedMessage: 'of ',
      date: '%s2 %s1, %s3 - %s5 %s4, %s6',
      showDetails: 'Show details',
      hideDetails: 'Hide details',
      tableTitle: 'KPIs details',
      columns: {
        kpi: 'KPI',
        status: 'Status',
        target: 'Target achieved',
        remaining: 'Remaining',
        rebate: 'Rebate',
        reward: 'Payout earned',
        type: 'Type',
      },
      status: {
        achieved: 'Achieved',
        notAchieved: 'Not achieved',
        expired: 'Expired',
      },
      table: {
        label: 'Past contract KPI',
        messages1:
          'Your client will receive a share of the payout for almost achieving 1 or more goals.',
        messages2: 'Your client will receive a bonus payout for overachieving 1 or more goals.',
        messages3:
          'Your client payouts have been adjusted as it have almost reached and overachieved 1 or more goals.',
      },
      unitsMeasurement: {
        store: 'store',
        stores: 'stores',
        percentage: '%',
        hectoliter: 'hL',
        case: 'Cs',
      },
    },
    abbreviatedMonthNames: {
      m01: 'Jan',
      m02: 'Feb',
      m03: 'Mar',
      m04: 'Apr',
      m05: 'May',
      m06: 'Jun',
      m07: 'Jul',
      m08: 'Aug',
      m09: 'Sep',
      m10: 'Oct',
      m11: 'Nov',
      m12: 'Dec',
    },
    selectWithSearch: {
      searchPlaceholder: 'Search by stores',
      showAllStores: 'Show all stores',
      storeName: '%storeName - %storeCode',
      allStores: 'All stores',
      noResults: 'No results. Review your search or try searching for a different store.',
    },
    tray: {
      currentTarget: 'Meta actual',
      currentRebate: 'Recompensa actual',
      targetLevels: 'Niveles de metas',
      textButton: 'Mostrar detalles del objetivo',
      tableHeader: {
        indicator: 'Nombre',
        target: 'Objetivo',
        targetRebate: 'Recompensa de la meta',
        status: 'Status',
      },
      statusLabel: {
        success: 'Éxito',
        inProgress: 'En progresso',
        notStarted: 'No iniciado',
        default: ' ',
      },
    },
  },
  kpiCard: {
    title: 'KPIs in the contract',
    amountToAchieve: 'to achieve',
    acceptTargets: 'Accept your targets to check the progress.',
    achievedLastMonth: 'achieved last month',
    allCompleted: '¡Se alcanzaron todas las metas!',
    name: 'KPIs',
  },
  pages: {
    home: {
      title: 'Commercial Agreements',
      subTitle: 'What information would you like to access?',
      navigationCards: {
        JBPManagement: {
          title: 'Commercial agreements management',
          subTitle: 'Define KPIs and commercial agreements',
        },
        JBPVisibility: {
          title: 'KPIs visibility',
          subTitle: 'Track KPIs progress by chain and POCs',
        },
        JBPContractManagment: {
          title: 'Contract management',
          subTitle: 'Create and manage your client contracts.',
        },
        JBPUpdateKPIs: {
          title: 'Actualización de metas',
          subTitle: 'Actualización masiva de los KPIs de progreso de cadenas y tiendas a la vez.',
        },
        CreateKPIsByUpload: {
          title: 'Contract Creation',
          subTitle: 'Bulk create contracts for multiple chains and stores at once.',
        },
      },
      informationMessage: {
        text: 'BEESHUB team is working hard to help the day-by-day on trade executions, new features are',
        highlight: 'coming soon..',
      },
    },
    JBPVisibility: {
      title: 'JBP Tracking',
      subTitle: 'Track JBP KPI progress by chains',
      chainTable: {
        idNumber: 'Client ID',
        chainName: 'Client name',
        type: 'Type',
        searchPlaceholder: 'Search by ID number or chain name',
        emptyMessage: 'Chain not found',
        loadingMessage: 'Loading clients...',
        group: 'Group',
        store: 'Store',
        independentStore: 'Independent store',
      },
      kpiPage: {
        title: 'Commercial agreements details',
        currentKpis: 'current KPIs',
        kpiSection: {
          growth: {
            title: 'Growth KPIs',
            subtitle: 'Get an overview of your general progress on growth driving.',
          },
          categoryGrowth: {
            title: 'Category growth KPIs',
            subtitle: 'Get an overview of the progress in each category.',
          },
          trade: {
            title: 'Trade KPIs',
            subtitle: 'Get an overview of the general progress on trade driving.',
          },
        },
      },
    },
    JBPContractManagment: {
      title: 'Contract management',
      subTitle: 'Create and manage your client contracts.',
      updateButton: 'Update contracts',
      createNewContract: 'Create contract',
      contractDetails: 'Contract details',
      table: {
        searchPlaceholder: 'Buscar por ID del contrato o cliente',
        client: 'Cliente',
        contractId: 'Contract ID',
        contractType: 'Tipo de contrato',
        status: {
          label: 'Status',
          active: 'Active',
          canceled: 'Canceled',
          expired: 'Expired',
        },
        startDate: 'Start date',
        endDate: 'End date',
        lastUpdate: 'Última actualización',
        date: '%day de %month de %year',
        actions: 'Actions',
        manageDrivers: 'Manage KPIs',
        cancelContract: 'Cancel contract',
      },
      create: {
        requiredField: 'Field required.',
        requiredValidNumber: 'Field required. Insert a valid number.',
        endEarlierStartDate: 'End date is earlier than start date',
        detailsStep: {
          label: 'Contract details',
          detail: 'Enter the chain information',
          selectChain: 'Select the chain',
          select: 'Select',
          contractId: 'Contract ID',
          insert: 'Insert',
          startDate: 'Start date of the contract',
          endDate: 'End date of the contract',
          datePlaceholder: 'MM/DD/YYYY',
          cancel: 'Cancel',
          continue: 'Continue',
        },
        driversStep: {
          label: 'Contract KPIs',
          detail: 'Define the contract targets.',
          driverName: 'KPI name',
          kpiCategory: 'KPI category',
          kpiCategorySub:
            'Only one KPI can be active per chain. All active contracts are considered.',
          select: 'Select',
          measurement: 'Measurement',
          target: 'Target',
          reward: 'Payout',
          optional: 'opcional',
          rewardPlaceholder: 'Insert payout',
          pageSection: 'Sección de página',
          enterName: 'Enter name',
          enterTarget: 'Insert target',
          addNew: 'Add new',
          addExisting: 'Add existing',
          addNewTitle: 'Create a new contract',
          addNewSubTitle: 'Create a new KPI to add to your contract.',
          editSubtitle: 'Edit the KPI information',
          addAdditional: 'Add new KPI',
          saveAndContinue: 'Finish',
          saveChanges: 'Save changes',
          successfullyCreated: 'KPI successfully created!',
          successfullyUpdated: 'KPI successfully updated!',
          alreadyInUse: 'This KPI category is already in use.',
          cardTitle: 'KPIs',
          cardDetail: 'Define contract targets and track progress.',
          cannotEditTargets: 'Once a contract has been created, you can only edit its progress.',
          addTargetsWillBeShown: 'The add levers will be shown here.',
          goBackButton: 'Go back',
          addContractButton: 'Add to contract',
          editDriver: 'Edit KPI',
          deleteDriver: 'Remove KPI',
          completed: 'Completed',
          notCompleted: 'Not completed',
          hectoliterSymbol: 'Hl',
          contractSuccessfullyCreated: 'Contract successfully created!',
          saveContractError: 'Something went wrong!',
          savePermissionError: "You don't have permission to perform this action.",
          sameContractId: 'This ID is already in use.',
          kpiSuccessfullyRemoved: 'KPI successfully removed!',
          modalDeleteDriver: {
            title: 'Remove KPI?',
            confirmButton: 'Yes, delete',
            cancelButton: 'No, go back',
          },
          notEnabled: 'Already in a contract',
          modalDriverAvailable: {
            title: 'Existing KPIs',
            closeButton: 'Check KPIs',
            message:
              "There's 1 or more KPI active in a current contract. Check the KPIs to create the contract.",
          },
        },
      },
      details: {
        title: 'Contract details',
        startDate: 'Start date',
        endDate: 'End date',
        cancel: 'Cancel',
        addNew: 'Add new',
        target: 'Target',
        reward: 'Payout',
        progress: 'Progress',
        openProgress: 'Open progress',
        deactivateDriver: 'Deactivate KPI',
        showTargetProgress: {
          title: 'Show target progress',
          description: 'Update your targets for each KPI to track the progress of the contract.',
        },
        updateDriver: {
          showProgress: 'Show progress',
          updateProgess: 'Update progress',
          save: 'Save',
          update: 'Update',
          status: 'Driver status:',
          kpiCategory: 'KPI category:',
          driverMeasurement: 'Driver measurement:',
          kpiMeasurement: 'KPI measurement:',
          target: 'Target:',
          progress: 'Progress',
          companyReachItsTarget: 'Did the company reach its target?',
          yes: 'Yes',
          no: 'No',
          driverUpdated: 'KPI sucessfully updated!',
          driverUpdateError: 'Something went wrong!',
          insertValidNumber: 'Insert a valid number.',
          permissionError: "You don't have permission to perform this action.",
        },
        measurement: {
          currency: 'Currency',
          number: 'Number',
          volHectoliter: 'Volume (hL)',
          volCase: 'Volume (Cs)',
          percentage: 'Percentage',
          volume: 'Volume',
          trueFalse: 'Completed/Not completed',
        },
        monthOfUpdate: {
          title: 'Month of update ',
          subtitle: 'Month that will be updated',
        },
      },
      delete: {
        title: 'Delete agreements?',
        subTitle: `You're deleting %agreements agreement from %clients client.`,
        detail: 'This action cannot be undone.',
        confirmButton: 'Yes, delete',
        cancelButton: 'No, go back',
        canceledContract: 'The contract was successfully cancelled.',
        canceledContractError: "You don't have permission to perform this action.",
      },
      modalCreateContracts: {
        title: 'Create contract',
        subtitle: 'Select how you want to create the contract:',
        options: {
          JBP: {
            title: 'JBP creation',
            subtitle: 'Create a single contract for a retailer group.',
          },
          IRS: {
            title: 'IRS creation',
            subtitle: 'Create multiple contracts for multiple stores at once.',
          },
        },
        continueButton: 'Continue',
      },
    },
    JBPUpdateKPIs: {
      title: 'Actualización de metas',
      subTitle: 'Actualización masiva de los KPIs de progreso de cadenas y tiendas a la vez.',
      uploadCard: {
        title: 'Cargar archivo',
        subTitle: 'Use the spreadsheet template to fill the update information.',
      },
      downloadSpreadsheet: {
        title: 'Descargar plantilla',
        spreadsheetJBP: 'Plantilla de JBP',
        spreadsheetIRS: 'Plantilla de IRS',
      },
      fileUploader: {
        message: 'File must be XLS or XLSX format and up to 25 MB in size.',
        button: 'Select file',
        uploadSuccess: 'File successfully uploaded!',
        uploadError: {
          tryAgain: 'Cargar un nuevo archivo',
          length: 'Select one file at a time.',
          size: 'El archivo superó el límite de tamaño. Revisa el archivo y inténtalo de nuevo.',
          format: 'Formato de archivo no compatible. Revisa el archivo y inténtalo de nuevo.',
          failed: 'Error al cargar. Inténtalo de nuevo.',
          messageServer: 'File upload failed. Try again or contact support.',
        },
      },
      fileSummary: {
        analysis: 'Análisis de archivos',
        fileBeingAnalyzed: 'Estamos analizando tus archivos.',
        dontCloseWindow: 'Mantén esta página abierta.',
        fileSummary: 'Análisis de archivos',
        chains: 'Chains',
        clients: 'Clientes',
        contracts: 'Metas',
        kpis: 'KPI',
        inconsistencies: 'Inconsistencias',
        alert:
          'Descarga el archivo analizado para revisar las inconsistencias y corregirlas. Después, cargalo de nuevo.',
        fileProcessingError:
          'No se puede analizar el archivo. Vuelve a cargar la página y carga el archivo otra vez.',
        reProcessFile: 'Inténtalo de nuevo',
      },
      downloadSummarySpreadsheet: 'Descargar archivo analizado',
      doneButton: 'Actualizar',
    },
    PINVisibility: {
      title: 'KPIs visibility',
      subTitle: 'Track KPIs progress by chain and POCs.',
      updateKpis: 'Update KPIs',
      chainTable: {
        idNumber: 'ID Number',
        chainName: 'Chain name',
        searchPlaceholder: 'Search by client ID or name',
        emptyMessage: 'Chain not found',
        loadingMessage: 'Loading clients...',
      },
      kpiPage: {
        title: 'Commercial agreements details',
        currentKpis: 'current KPIs',
        pinLevers: {
          title: 'Invoice levers KPIs',
          subtitle: 'Get an overview of the progress of your KPIs.',
          onLeverClicked: 'Lever accepted! Follow up lever to get the payout.',
          cards: {
            loading: 'Loading levers',
            loadingAux: "We're almost there...",
            incompleteTargetText: '%s1 is missing for the target of %s2',
            completeTargetText: 'Target achieved, %s',
            potentialReward: 'Potential payout',
            toBeAccepted: 'To be accepted',
            accepted: 'Accepted',
            tooltipText: 'This lever includes the following items:',
          },
          homeButton: 'Home',
          tryAgainButton: 'Try again',
          errors: {
            tryAgainText: 'Try again',
            homeText: 'Home',
            '403': {
              title: 'Humm... Access denied!',
              subtitle: "It looks like this page wasn't found or doesn't exist.",
            },
            '404': {
              title: 'Mmm... Looks like this page is gone.',
              subtitle: "It looks like this page wasn't found or doesn't exist.",
            },
            '503': {
              title: 'Hm... Service unavailable!',
              subtitle:
                'Our servers are temporarily too busy. You can try again in a few minutes, and it should be normalized.',
              auxText: 'If the issue persists, you can report the problem.',
            },
            default: {
              title: 'Hm... Service unavailable!',
            },
          },
        },
      },
    },
    newInsightsPage: {
      title: 'Commercial agreements details',
      subTitle: 'Track the KPIs of the chain you manage.',
      contractSummary: {
        title: 'Contract summary',
        activeOneContractsLabel: 'active contract',
        activeMultiContractsLabel: 'active contracts',
        showContracts: 'Show contract',
      },
      activeContracts: {
        title: 'Active contracts',
        subtitle: 'Access contracts details and participating stores.',
        idLabel: 'ID',
        typeLabel: 'Type',
        date: 'Valid from {startMonth} {startDay}, {startYear} to {endDay} {endMonth}, {endYear}',
      },
      history: {
        details: {
          kpi: 'KPI',
          status: 'Status',
          target: 'Target achieved',
          remaining: 'Remaining',
          reward: 'Payout earned',
          rebate: 'Rebate',
          notAchieved: 'Not achieved',
          achieved: 'Achieved',
          expired: 'Expired',
          almostAchieved: 'Partially achieved',
          overAchieved: 'Exceeded ',
          partiallyAchieved: 'Partially achieved',
          type: 'Type',
        },
        error: 'Unable to load contract details. Reload this page and try again.',
      },
      currentContract: {
        generalGroup: {
          title: 'General KPIs',
          description: ' ',
        },
        totalGrowth: {
          title: 'Total growth KPI',
          description: 'All orders made by your chain.',
        },
        categoryGrowth: {
          title: 'Category growth KPIs',
          description: 'Orders on specific categories made by your chain.',
        },
        shareSpace: {
          title: 'Share space KPIs',
          description: 'Products displayed in shelf space of point of sale.',
        },
        irsKpi: {
          title: 'IRS KPI',
          description: 'All bulk products ordered by your chain.',
        },
        metasMix: {
          title: 'Objetivos de combinación',
          description: 'Progreso de tu grupo en categorías específicas.',
        },
        metasVolumen: {
          title: 'Objetivos de volumen',
          description: 'Progreso de tu grupo en categorías específicas.',
        },
        metasDePerfomance: {
          title: 'Objetivos de desempeño',
          description: 'Progreso de tu grupo en categorías específicas.',
        },
        totalVolume: {
          title: 'Total volume',
          description: '',
        },
        convenienceVolume: {
          title: 'Convenience volume',
          description: '',
        },
        totalGrowthTitle: 'Total growth KPI',
        totalGrowthDescription: 'All orders made by your chain.',
        categoryGrowthTitle: 'Category growth KPIs',
        categoryGrowthDescription: 'Orders on specific categories made by your chain.',
        shareSpaceTitle: 'Share space KPIs',
        shareSpaceDescription: 'Products displayed in shelf space of point of sale.',
      },
      currentContractTab: 'Current contract',
      historyTab: 'Past contracts',
    },
    CreateKPIsByUpload: {
      title: 'Contract creation',
      subTitle: 'Bulk create contracts for multiple chains and stores at once.',
      uploadCard: {
        title: 'Upload a file',
        subTitle: 'Use the spreadsheet template to fill the update information.',
      },
      downloadSpreadsheet: 'Download spreadsheet template',
      doneButton: 'Create',
    },
  },
};
