/* eslint-disable @typescript-eslint/no-empty-function */
import { Badge, ProgressMeter, Tooltip } from '@hexa-ui/components';
import { Info, Target } from '@hexa-ui/icons';
import Big from 'big.js';
import { useIntl } from 'react-intl';
import { useStateWithLocalStorage } from '../../utils/useStateWithLocalStorage';
import InvoiceLeverLoading from './InvoiceLeverLoading/InvoiceLeverLoading';
import NumberDisplay, { numberFormat } from '../NumberDisplay';
import {
  Card as CardStyled,
  LeverContainer,
  LeverLabel,
  LeverTitle,
  NumberLabel,
  PotentialReward,
  ProgressMeterContainer,
  RewardSummary,
  RewardValue,
  TargetSummary,
  TooltipContainer,
} from './InvoiceLever.style';

export type TLever = {
  id: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  email?: string;
  accountId?: string;
  cdd_und?: number;
  cod_pdv?: number;
  lever?: string;
  lever_items?: string;
  target_kpi?: number;
  actual_kpi?: number;
  percent_of_gol?: number;
  reward?: number;
  accept?: boolean;
  idPin?: string;
};

export type TInvoiceLeverProps = {
  lever: TLever;
  locale: string;
  setAlerts?: (alerts: any) => void;
  isAccepted: boolean;
};

export function InvoiceLever({
  locale,
  lever,
  setAlerts = () => {},
  isAccepted,
}: TInvoiceLeverProps) {
  const { formatMessage } = useIntl();
  if (lever?.isLoading) {
    return <InvoiceLeverLoading />;
  }
  const targetKpi = Number(lever?.target_kpi || 0);
  const actualKpi = Number(lever?.actual_kpi || 0);
  const difference = new Big(targetKpi).minus(actualKpi).toString();
  const items = lever?.lever_items.split(',');

  const targetAmount = numberFormat(targetKpi, locale, 'currency');
  const missingTargetText = formatMessage({ id: 'leverBar.missingTargetSecond' });

  let isTargetAchieved = '';

  if (!isAccepted) {
    isTargetAchieved = `${targetAmount} ${missingTargetText} ${targetAmount}`;
  } else if (lever?.percent_of_gol >= 100) {
    isTargetAchieved = `${formatMessage({
      id: 'pages.PINVisibility.kpiPage.pinLevers.cards.completeTargetText',
    }).replace(', %s', '')} - ${targetAmount}`;
  }

  const isTargetPending =
    lever?.percent_of_gol !== 100
      ? formatMessage({ id: 'pages.PINVisibility.kpiPage.pinLevers.cards.incompleteTargetText' })
          .replace('%s1', numberFormat(Number(difference), locale, 'currency'))
          .replace('%s2', numberFormat(lever?.target_kpi, locale, 'currency'))
      : formatMessage({
          id: 'pages.PINVisibility.kpiPage.pinLevers.cards.completeTargetText',
        }).replace('%s', numberFormat(lever?.target_kpi, locale, 'currency'));

  const getAcceptedLabel = (label: string | number) => {
    return `${!isAccepted ? 0 : Math.round(Number(label))}%  - `;
  };

  return (
    <CardStyled border="medium" elevated="medium">
      <LeverContainer>
        <LeverTitle data-testid={'lever-title'}>{lever?.lever}</LeverTitle>
        <TooltipContainer>
          <Tooltip
            placement="bottom"
            text={`${formatMessage({
              id: 'pages.PINVisibility.kpiPage.pinLevers.cards.tooltipText',
            })} ${items.join(', ')}`}
          >
            <Info width={'1.5rem'} height={'1.5rem'} />
          </Tooltip>
        </TooltipContainer>
      </LeverContainer>
      <LeverLabel data-testid={'lever-label'}>
        <NumberLabel data-testid={'percent-of-gol'}>
          {getAcceptedLabel(lever?.percent_of_gol)}
        </NumberLabel>
        <NumberDisplay
          attributes={{
            value: !isAccepted ? 0 : lever?.actual_kpi,
            locale,
            style: 'currency',
          }}
        />
      </LeverLabel>
      <ProgressMeterContainer data-testid={'progress-meter'}>
        <ProgressMeter maxValue={100} value={!isAccepted ? 0 : Number(lever?.percent_of_gol)} />
      </ProgressMeterContainer>
      <TargetSummary size={'basis'}>
        <Target height={'.75rem'} width={'.75rem'} />
        <span data-testid={'target-summary'}>
          {isTargetAchieved ? isTargetAchieved : isTargetPending}
        </span>
      </TargetSummary>
      <RewardSummary>
        <div>
          <PotentialReward data-testid={'potential-reward'} size={'xsmall'}>
            {formatMessage({ id: 'pages.PINVisibility.kpiPage.pinLevers.cards.potentialReward' })}
          </PotentialReward>
          <RewardValue data-testid={'reward-value'} size={'small'}>
            {numberFormat(lever?.reward, locale, 'currency')}
          </RewardValue>
        </div>
        <div data-testid={'acceptance'}>
          {!isAccepted ? (
            <Badge.Status
              data-testid={'toBeAccepted'}
              color="yellow"
              type="default"
              label={formatMessage({
                id: 'pages.PINVisibility.kpiPage.pinLevers.cards.toBeAccepted',
              })}
            ></Badge.Status>
          ) : (
            <Badge.Status
              data-testid={'accepted'}
              color="green"
              type="default"
              label={formatMessage({ id: 'pages.PINVisibility.kpiPage.pinLevers.cards.accepted' })}
            ></Badge.Status>
          )}
        </div>
      </RewardSummary>
    </CardStyled>
  );
}
