import { ChevronLeft, Filter } from '@hexa-ui/icons';
import { FilterSelected } from '../FilterSelected/FilterSelected';
import { useFilterContext } from '../context/FilterContext';
import * as Styles from './FilterTopbar.styles';

export const FilterTopbar = () => {
  const { drawerOpen, handleDrawerOpen, handleDrawerClose, labels } = useFilterContext();

  return (
    <Styles.Container data-testid="filter-topbar" direction="row">
      <Styles.Button
        icon={drawerOpen ? ChevronLeft : Filter}
        variant="tertiary"
        onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
      />
      <Styles.Message>{labels?.topbarMessage}</Styles.Message>
      <FilterSelected />
    </Styles.Container>
  );
};
