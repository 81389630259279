import { IntlProvider, QueryProvider } from '@providers';
import { RenderOptions, render } from '@testing-library/react';
import { ReactNode } from 'react';
import { MemoryRouter, MemoryRouterProps } from 'react-router-dom';

export const renderComponent = (
  component: ReactNode,
  memoryRouterProps: MemoryRouterProps = {},
  renderOptions?: RenderOptions
) => {
  return render(
    <IntlProvider>
      <MemoryRouter {...memoryRouterProps}>
        <QueryProvider>{component}</QueryProvider>
      </MemoryRouter>
    </IntlProvider>,
    { ...renderOptions }
  );
};
