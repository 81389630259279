import { Table as HexaTable } from '@hexa-ui/components';
import { Filter } from './Filter/Filter';
import { Message } from './Message/Message';
import { TableProps } from './Table.types';

export const Table = <T,>({
  columns,
  data,
  isLoading,
  search,
  pagination,
  filter,
  labels,
  toolbarExtra,
  onSearchChange,
  onSort,
  selectable,
}: TableProps<T>) => {
  const table = (
    <HexaTable
      columns={columns}
      data={data}
      loading={isLoading}
      loadingMessage={(<Message message={labels?.loadingMessage} />) as unknown as string}
      emptyMessage={<Message message={labels?.emptyMessage} />}
      searchPlaceholder={labels?.searchPlaceholder}
      pagination={pagination}
      search={search}
      toolbarExtra={toolbarExtra}
      onSearchChange={onSearchChange}
      selectable={selectable}
      onSort={onSort}
    />
  );

  if (filter) {
    const { storageKey, fields, onChangeFilters } = filter;

    return (
      <Filter
        storageKey={storageKey}
        fields={fields}
        table={table}
        labels={labels}
        onChange={onChangeFilters}
      />
    );
  }

  return table;
};

export * from './Filter/Filter.types';
// eslint-disable-next-line prettier/prettier
export * from './Table.types';
