import { referrers } from 'constants/segment';
import { useNavigate } from 'react-router-dom';
import { contractManagementPath, updateKPIsPath, visibilityPath } from 'routes/Definition';
import {
  contractManagementStarted,
  jbpVisibilityStarted,
  updateKpIsStarted,
} from '../../../analytics';
import { INavigationCardProps } from './NavigationCard.props';
import {
  CardContent,
  CardParagraph,
  CardStyled,
  CardTexts,
  CardTitle,
} from './NavigationCard.styles';

export const NavigationCard = ({
  title,
  subTitle,
  icon,
  path,
  segmentScreenName,
  selectedCountry,
}: INavigationCardProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (path === visibilityPath) {
      jbpVisibilityStarted({
        screen_name: segmentScreenName,
      });
    } else if (path === contractManagementPath) {
      contractManagementStarted({
        screen_name: segmentScreenName,
      });
    } else if (path === updateKPIsPath) {
      updateKpIsStarted({
        screen_name: segmentScreenName,
        country: selectedCountry,
        referrer: referrers.linkAdmin,
      });
    }
    navigate(path);
  };

  return (
    <CardStyled
      data-testid="navigation-card"
      border="small"
      elevated="medium"
      onClick={handleClick}
    >
      <CardContent>
        {icon}
        <CardTexts>
          <CardTitle weight="medium">{title}</CardTitle>
          <CardParagraph size="small">{subTitle}</CardParagraph>
        </CardTexts>
      </CardContent>
    </CardStyled>
  );
};
