import { currencyCode } from '@utils';
import { ICurrencyProps } from './Currency.props';

export const Currency = ({
  country,
  value,
  customLocale,
  customCurrency,
}: ICurrencyProps): JSX.Element => {
  if (customLocale === 'en-ZA' || country === 'ZA') {
    return (
      <>
        {new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
          style: 'currency',
          currency: customCurrency || currencyCode[country].currency,
        })
          .format(value)
          .replace(/,/g, ' ')
          .replace('.', ',')}
      </>
    );
  } else {
    return (
      <>
        {new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
          style: 'currency',
          currency: customCurrency || currencyCode[country].currency,
        }).format(value)}
      </>
    );
  }
};

export const CurrencyText = ({
  country,
  value,
  customLocale,
  customCurrency,
}: ICurrencyProps): string => {
  if (customLocale === 'en-ZA' || country === 'ZA') {
    return new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
      style: 'currency',
      currency: customCurrency || currencyCode[country].currency,
    })
      .format(value)
      .replace(/,/g, ' ')
      .replace('.', ',');
  } else {
    return new Intl.NumberFormat(customLocale || currencyCode[country].locale, {
      style: 'currency',
      currency: customCurrency || currencyCode[country].currency,
    }).format(value);
  }
};
