import { FilterTopbar } from '../FilterTopbar/FilterTopbar';
import { useFilterContext } from '../context/FilterContext';
import * as Styles from './FilterContent.styles';

export const FilterContent = () => {
  const { table } = useFilterContext();

  return (
    <Styles.Container data-testid="filter-content">
      <FilterTopbar />
      <Styles.TableContainer empty={!table.props.data?.length}>{table}</Styles.TableContainer>
    </Styles.Container>
  );
};
