import {
  setAppHeaderConfig,
  useAuthenticationService,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { useEffect } from 'react';
import { IDefaultLayoutProps } from './DefaultLayout.props';

export const DefaultLayout = ({ children }: IDefaultLayoutProps) => {
  const { data = { supportedCountries: [] } } = useUserMetadata();
  const authentication = useAuthenticationService();
  const { user_country } = authentication.getUserCountryAndLanguage();

  useEffect(() => {
    setAppHeaderConfig({
      countryOptions: data.supportedCountries,
      defaultCountry: `${user_country}`,
    });
  }, [data.supportedCountries, user_country]);

  return <>{children}</>;
};
