import { useQuery } from '@tanstack/react-query';
import { useUserMetadata } from 'admin-portal-shared-services';
import { authentication, get } from '../../api';
import { TStakeholderResponse } from './StakeholderService.types';

export const useStakeholderService = () => {
  const { data: userData } = useUserMetadata();
  const email = authentication.getUserEmailB2C();

  const {
    data = {
      content: [],
    },
  } = useQuery<TStakeholderResponse>({
    queryKey: ['stakeholder'],
    queryFn: () => {
      const url =
        'api/link-commercial-kpis-service/stakeholder/chainsByUser' +
        `?user_id=${userData.userId}` +
        `&email=${email}`;

      return get({
        url,
      });
    },
    enabled: !!userData,
  });

  return { data: data.content };
};
