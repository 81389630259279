import MessageMap from '../providers/i18nProvider/i18n.d';
import { enUS } from './languages/en-US';
import { esAR } from './languages/es-AR';
import { esMX } from './languages/es-MX';
import { ptBr } from './languages/pt-BR';

const messages: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBr,
  'es-MX': esMX,
  'es-AR': esAR,
};

export default messages;
