import { useQuery } from '@tanstack/react-query';
import { useAppHeader, usePermissionService } from 'admin-portal-shared-services';
import { get } from '../../api';

export const useSidebarService = () => {
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const permissionService = usePermissionService();

  const { data = [], isSuccess } = useQuery(['sidebar', selectedCountry], async () => {
    const response = await get({
      url: 'api/modern-trade-management/mfe',
      config: {
        headers: {
          requestTraceId: 'sidebar',
          country: selectedCountry,
        },
      },
    });
    /* istanbul ignore next */
    return response.content.filter((res) =>
      res.id == 'common'
        ? permissionService.hasScopesToApp(`${res.permission}`)
        : permissionService.hasOneOfScopes(`${res.permission}`)
    );
  });

  return { data, isSuccess };
};
