import { styled } from '@hexa-ui/theme';
import { Stack } from '@mui/material';

export const Container = styled(Stack, {
  width: '100%',
  overflow: 'auto',
});

export const TableContainer = styled(Stack, {
  height: '100%',
  variants: {
    empty: {
      true: {
        '& > div': {
          height: '100%',
          display: 'flex',

          '& > div:nth-child(1)': {
            flex: '1',

            '& > table': {
              minHeight: '100%',
            },
          },
        },
      },
      false: {
        '& > div': {
          height: '100%',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',

          '& > div:nth-child(1)': {
            flex: '1',
          },
        },
      },
    },
  },
});
