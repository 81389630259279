import { Button, Input as HexaInput } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { Stack } from '@mui/material';

export const Container = styled(Stack, {
  padding: '$8 $6',
});

export const Input = styled(HexaInput, {
  height: '2.5rem !important',
});

export const ApplyButton = styled(Button, {
  width: 'fit-content',
});
