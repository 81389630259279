import { MarginBottom8px } from './../KpiComponent/KpiComponent.style';
import { Target } from '@hexa-ui/icons';
import { RewardContainer } from './../../pages/ContractManagement/Create/sub-components/DriversStep/ModalAddDriver/AddNew/AddNew.styles';
import { Heading, Card as HexaCard, Divider as HexaDivider, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  width: '43rem',
  height: '15rem',
  padding: '1.5rem',
  margin: '0.5rem',
  flexDirection: 'column',
  gap: '0.5rem',
  flexShrink: '0',
  zIndex: '1',
});

export const LeverContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '0.5rem',
});

export const LeverTitle = styled('span', {
  fontFamily: '$paragraph',
  alignItems: 'center',
  fontSize: '1.25rem',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '1.5rem' /* 120% */,
});

export const TooltipContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '$1',
  paddingTop: '0.20rem',
});

export const LeverLabel = styled(Paragraph, {
  marginBottom: '0.5rem',
});
export const NumberLabel = styled('span', {
  fontSize: '2rem',
});

export const ProgressMeterContainer = styled('div', {});

export const TargetSummary = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
});

export const TargetText = styled('span', {
  fontSize: '0.875rem',
  lineHeight: '1.25rem' /* 142.857% */,
});

export const RewardSummary = styled(Paragraph, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  maxWidth: '100%',
  marginBottom: '0.5rem',
});

export const PotentialReward = styled(Paragraph, {
  fontSize: '0.75rem',
  fontWeight: '400',
  lineHeight: '1.125rem' /* 150% */,
});
export const RewardValue = styled(Paragraph, {
  display: 'flex',
  flexDirection: 'collumn',
  width: '100%',
});
