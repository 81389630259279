import { TableLabelsProps } from '@components';
import { ReactElement, createContext, useContext } from 'react';
import { FieldsFilterType, FiltersType } from '../Filter.types';

type FilterContextType = {
  fields: FieldsFilterType;
  filters: FiltersType;
  table: ReactElement;
  labels?: TableLabelsProps;
  tempFilters: FiltersType;
  handleDelete: (field: string) => void;
  handleClear: () => void;
  handleChange: (field: string, value: string) => void;
  handleApply: () => void;
  drawerOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
};

/* istanbul ignore next */
const initialValues: FilterContextType = {
  fields: {},
  filters: {},
  table: <></>,
  tempFilters: {},
  handleDelete: () => null,
  handleClear: () => null,
  handleChange: () => null,
  handleApply: () => null,
  drawerOpen: true,
  handleDrawerOpen: () => null,
  handleDrawerClose: () => null,
};

export const FilterContext = createContext(initialValues);
export const useFilterContext = (): FilterContextType => useContext(FilterContext);
