import { Alert } from '@hexa-ui/components';

export type TypesOfAlert = 'info' | 'success' | 'warning' | 'error';

export type AlertType = {
  id: string;
  message: string;
  type: TypesOfAlert;
};

export type AlertStackComponentProps = {
  alerts: AlertType[];
  setAlerts: React.Dispatch<React.SetStateAction<AlertType[]>>;
};

export const AlertStack = ({ alerts, setAlerts }) => (
  <div className="alert-stack">
    {alerts.map((error) => (
      <Alert
        className={'alert-multiple-files'}
        key={error.id}
        //isOpen={true}
        componentVariant="toast"
        type={error.type}
        //time="default"
        //dismissable={true}
        message={error.message}
        onClose={() => setAlerts((prevErrors) => prevErrors.filter((e) => e.id !== error.id))}
        showCloseButton={true}
      />
    ))}
  </div>
);
