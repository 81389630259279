import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services';
import { useEffect } from 'react';

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();
  const authentication = useAuthenticationService();

  useEffect(() => {
    analyticsService.load(segmentKey);
  }, [analyticsService, segmentKey]);

  const traits = {
    type: 'identify',
    userId: `${authentication.getUserId()}`,
  };

  analyticsService.identify(traits);
};
