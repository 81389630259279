import { EnvConfig, EnvProvider, IntlProvider, QueryProvider, ThemeProvider } from '@providers';
import { PropsWithChildren } from 'react';

export function GlobalProvider(props: PropsWithChildren<EnvConfig>) {
  return (
    <QueryProvider>
      <EnvProvider env={props}>
        <ThemeProvider>
          <IntlProvider>{props.children}</IntlProvider>
        </ThemeProvider>
      </EnvProvider>
    </QueryProvider>
  );
}
