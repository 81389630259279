import { PageLoader } from '@components';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { lazy, LazyExoticComponent, ReactElement, Suspense } from 'react';

export type Modules =
  | 'COMMERCIAL_AGREEMENTS'
  | 'VISIBILITY'
  | 'PINS_VISIBILITY'
  | 'LEVERS'
  | 'PINS_LEVERS'
  | 'CONTRACT_MANAGEMENT'
  | 'CONTRACT_CREATE'
  | 'CONTRACT_DETAILS'
  | 'UPDATE_KPIS'
  | 'CATALOG_PAGE'
  | 'INSIGHTS_PAGE'
  | 'CREATE_UPLOAD_PAGE';

type Route = {
  path: string;
  Element: LazyExoticComponent<() => ReactElement>;
  children?: Route[];
};

export const userPermissions = {
  read: 'LinkAdmin.CommA.read',
  write: 'LinkAdmin.CommA.write',
  all: ['LinkAdmin.CommA.read', 'LinkAdmin.CommA.write'],
};

type RoutesProps = Record<Modules, Route>;

const CommercialAgreementsPage = lazy(() => import('pages/CommercialAgreements'));
const VisibilityPage = lazy(() => import('pages/Visibility'));
const LeversPage = lazy(() => import('pages/Levers'));
const ContractManagementPage = lazy(() => import('pages/ContractManagement'));
const ContractCreatePage = lazy(() => import('pages/ContractManagement/Create'));
const ContractDetailsPage = lazy(() => import('pages/ContractManagement/Details'));
const UpdateKPIsPage = lazy(() => import('pages/UpdateKPIs'));
const PinsPage = lazy(() => import('pages/Pins'));
const NewInsightsPage = lazy(() => import('pages/NewInsights'));

export const homePath = '/link-admin';
export const commercialAgreementsPath = '/bees-comm-agreemts';
export const visibilityPath = `${commercialAgreementsPath}/chains`;
export const leversPath = `/levers`;
export const dealsPath = `/deals`;
export const contractManagementPath = `${commercialAgreementsPath}/contract-management`;
export const contractCreatePath = `${contractManagementPath}/create`;
export const contractDetailsPath = `${contractManagementPath}/details`;
export const updateKPIsPath = `${commercialAgreementsPath}/update-kpis`;
export const catalogPagePath = `${commercialAgreementsPath}/catalog-page`;
export const newInsightsPath = `${commercialAgreementsPath}/new-insights`;
export const createByUploadPath = `${contractManagementPath}/create-by-upload`;

export const Routes: RoutesProps = {
  COMMERCIAL_AGREEMENTS: { path: commercialAgreementsPath, Element: CommercialAgreementsPage },
  VISIBILITY: { path: visibilityPath, Element: VisibilityPage },
  PINS_VISIBILITY: { path: visibilityPath, Element: VisibilityPage },
  LEVERS: { path: `${visibilityPath}/:chainId${leversPath}`, Element: LeversPage },
  PINS_LEVERS: { path: `${visibilityPath}/:chainId${leversPath}`, Element: PinsPage },
  CONTRACT_MANAGEMENT: { path: contractManagementPath, Element: ContractManagementPage },
  CONTRACT_CREATE: { path: contractCreatePath, Element: ContractCreatePage },
  CONTRACT_DETAILS: { path: contractDetailsPath, Element: ContractDetailsPage },
  UPDATE_KPIS: { path: updateKPIsPath, Element: UpdateKPIsPage },
  CATALOG_PAGE: { path: catalogPagePath, Element: NewInsightsPage },
  INSIGHTS_PAGE: { path: `${newInsightsPath}/:chainId${leversPath}`, Element: NewInsightsPage },
  CREATE_UPLOAD_PAGE: { path: createByUploadPath, Element: UpdateKPIsPage },
};

export const modules = [
  Routes.COMMERCIAL_AGREEMENTS,
  Routes.VISIBILITY,
  Routes.LEVERS,
  Routes.CONTRACT_MANAGEMENT,
  Routes.CONTRACT_CREATE,
  Routes.CONTRACT_DETAILS,
  Routes.UPDATE_KPIS,
  Routes.PINS_VISIBILITY,
  Routes.PINS_LEVERS,
  Routes.CATALOG_PAGE,
  Routes.INSIGHTS_PAGE,
  Routes.CREATE_UPLOAD_PAGE,
];

export const routesConfig = modules.map(({ path, Element }) => ({
  path,
  element: (
    <Suspense fallback={<PageLoader />}>
      <ProtectedRoute isEnabled permission={userPermissions.all}>
        {Element ? <Element /> : <></>}
      </ProtectedRoute>
    </Suspense>
  ),
}));
