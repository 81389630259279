import { IconButton, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { Stack } from '@mui/material';

export const Container = styled(Stack, {
  width: '100%',
  alignItems: 'center',
  minHeight: 61,
});

export const Button = styled(IconButton, {
  marginLeft: -20,
  background: '$neutral20 !important',
  zIndex: 2,
  position: 'absolute',
  '&:hover': {
    backgroundColor: '$brandPrimaryBasis !important',
  },
});

export const Message = styled(Paragraph, {
  margin: '1.15rem 0 1.15rem 2.25rem !important',
});
