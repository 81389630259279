import { Button as HexaButton, Heading as HexaHeading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { Drawer as MuiDrawer } from '@mui/material';

export const Drawer = styled(MuiDrawer, {
  flexShrink: 0,
  overflow: 'hidden',
  borderRadius: '$4 0 0 $4',
  boxShadow: '$4 0 0 $4',
  zIndex: 1,
  borderRight: '1px solid $neutral20',
  transition: 'width 200ms',
  '& .MuiDrawer-paper': {
    width: 238,
    position: 'relative',
    border: 'none',
  },
  variants: {
    drawerOpen: {
      true: {
        width: 238,
      },
      false: {
        width: 24,
      },
    },
  },
});

export const DrawerHeader = styled('div', {
  display: 'flex',
  height: 62,
  borderBottom: '1px solid $neutral20',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 $8 0 $6',
});

export const Button = styled(HexaButton, {});

export const Heading = styled(HexaHeading, {});
