import { getChains, getChainsAndPocs, getChainsSearch } from '@api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppHeader, useUserMetadata } from 'admin-portal-shared-services';
import { useState } from 'react';
import {
  TUseChain,
  TUseChainAndPocProps,
  TUseChainBySearchProps,
  TUseChainProps,
} from './useChain.props';
// import { authentication } from '../../api';

export const CHAIN_QUERY_KEY = 'chains';

export const useChain = () => {
  const [appHeaderConfig] = useAppHeader();
  const queryClient = useQueryClient();
  const { selectedCountry } = appHeaderConfig;
  const { data: userData } = useUserMetadata();
  // const dataJwt = authentication.parseJwt();
  const userId = !userData ? '' : userData.userId;
  const [errorStatus, setErrorStatus] = useState<number>();

  const useGetAllChains = ({ pageNumber, pageSize }: TUseChain) => {
    const {
      data = {
        content: [],
        pagination: {
          page: 0,
          pageSize: 0,
          totalPages: 0,
          totalElements: 0,
        },
      },
      isSuccess,
      isLoading,
      isFetching,
      isError,
      error,
    }: TUseChainProps = useQuery({
      queryKey: [CHAIN_QUERY_KEY, pageNumber, pageSize, userId],
      queryFn: ({ signal }) =>
        getChains({
          userId,
          pageNumber,
          pageSize,
          signal,
          country: selectedCountry,
        }),
      onError: (err) => {
        queryClient.setQueryData(['chains', userId, pageNumber, pageSize], {
          chains: [],
        });
        setErrorStatus(err?.response?.status);
      },
      keepPreviousData: false,
      enabled: !!userData,
    });

    const standardizedData = data.content.map((chains) => {
      const result = {
        idNumber: chains.chain.zoneChainId,
        chainId: chains.chain.id,
        name: chains.chain.name,
      };
      return result;
    });

    return {
      data: standardizedData,
      pagination: data.pagination,
      isSuccess,
      isError,
      isLoading: isLoading || isFetching,
      error,
      status: errorStatus,
    };
  };

  const useChainSearch = ({ pageNumber, pageSize, term }: TUseChain) => {
    const {
      data = {
        content: [],
        pagination: {
          page: 0,
          pageSize: 0,
          totalPages: 0,
          totalElements: 0,
        },
      },
      isSuccess,
      isLoading,
      isFetching,
      isError,
      error,
    }: TUseChainBySearchProps = useQuery({
      queryKey: [CHAIN_QUERY_KEY, pageNumber - 1, pageSize, term, userId],
      queryFn: ({ signal }) =>
        getChainsSearch({
          userId,
          pageNumber: pageNumber - 1,
          pageSize,
          term,
          signal,
          country: selectedCountry,
        }),
      onError: (err) => {
        queryClient.setQueryData(['chains', userId, pageNumber, pageSize], {
          chains: [],
        });
        setErrorStatus(err?.response?.status);
      },
      keepPreviousData: false,
      enabled: !!userData,
    });
    const standardizedData = data.content.map((chains) => {
      const result = {
        idNumber: chains.zoneChainId,
        chainId: chains.id,
        name: chains.name,
      };
      return result;
    });

    return {
      data: standardizedData,
      pagination: data.pagination,
      isSuccess,
      isError,
      isLoading: isLoading || isFetching,
      error,
      status: errorStatus,
    };
  };

  const useGetAllChainsAndPocs = ({ pageNumber, pageSize, term }: TUseChain) => {
    const adjustedPageNUmber = pageNumber - 1;
    const {
      data = {
        content: [],
        pagination: {
          page: 0,
          pageSize: 0,
          totalPages: 0,
          totalElements: 0,
        },
      },
      isSuccess,
      isLoading,
      isFetching,
      isError,
      error,
    }: TUseChainAndPocProps = useQuery({
      queryKey: [CHAIN_QUERY_KEY, pageNumber, pageSize, userId, term],
      queryFn: ({ signal }) =>
        getChainsAndPocs({
          userId,
          pageNumber: adjustedPageNUmber,
          pageSize,
          signal,
          country: selectedCountry,
          term,
        }),
      onError: (err) => {
        queryClient.setQueryData(['chainsAndPocs', userId, pageNumber, pageSize], {
          chains: [],
        });
        setErrorStatus(err?.response?.status);
      },
      keepPreviousData: false,
      enabled: !!userData,
    });

    const standardizedData = data.content.map(
      ({ clientId, clientName, clientType, vendorId, chainId }) => ({
        clientId,
        clientName,
        clientType,
        vendorId,
        chainId,
      })
    );

    return {
      data: standardizedData,
      pagination: data.pagination,
      isSuccess,
      isError,
      isLoading: isLoading || isFetching,
      error,
      status: errorStatus,
    };
  };
  return { useGetAllChains, useChainSearch, useGetAllChainsAndPocs };
};

export * from './useChain.props';
