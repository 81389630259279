import { useSegmentAnalytics } from '@hooks';
import { DefaultLayout } from '@layouts';
import { EnvConfig, GlobalProvider } from '@providers';
import { Router } from 'routes';

export default function App(props: EnvConfig) {
  const { SEGMENT_KEY } = props;
  useSegmentAnalytics(SEGMENT_KEY);

  return (
    <GlobalProvider {...props}>
      <DefaultLayout>
        <Router />
      </DefaultLayout>
    </GlobalProvider>
  );
}
